<template>
	<div>
		<div>
			<el-image :src="image"></el-image>
			
			<div style="display: flex;justify-content: center;padding-top: 20px;">
				<c-button type="warning" :height="50" :width="250" style="margin-left: 10px;" @click="jump">点击跳转微信小程序</c-button>
			</div>
		</div>
	</div>
</template>

<script>
	import CButton from '@/components/CButton/index.vue' // button组件引入
	export default {
		components: {
			CButton
		},
		data() {
			return {
				image: process.env.VUE_APP_OSS_URL + '/home/bg2.jpg',
				openlink:null,
			}
		},
		
		created() {
			this.openlink=this.$route.query.openlink
		},
		
		mounted() {
			
		},
		
		watch:{
			
		},
		
		methods:{
			jump:function(){
				window.location.href = this.openlink
			}
		}
	}
</script>

<style lang="less" scoped>
	
</style>